import { NgModule } from '@angular/core';
import { BookingComponent } from './booking/booking.component';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './not-found/not-found.component';
import { AppComponent } from './app.component';

const appRoutes : Routes = [
    { path : '',component: AppComponent},
    { path : 'booking', component: BookingComponent, children : [
        { path : ':id', redirectTo: '/booking/id' }
      ] 
    }

  ];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, {useHash: true})
    ],
    exports: [RouterModule],
})

export class AppRoutingModule{

}