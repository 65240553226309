import { Component, OnInit, Output, NgZone, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BookingService } from '../booking.service';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.css']
})
export class BookingComponent implements OnInit {

  constructor(
    private zone: NgZone,
    private bookingService: BookingService
  ) { }
  transportType: number;
  transportName: string;
  coordinatesFrom: number[] = [];
  coordinatesTo: number[] = [];
  durations: string[] = [];
  prices: number[] = [];
  carsDisabled = false;
  vansDisabled = false;
  waitingTimeLoaded = false;
  waitingTimes: string[] = [];
  vehicleTypes = {
    COMFORT: 1,
    LUXURY: 4,
    VAN : 5,
    GREEN: 8
  }
  //
  @ViewChild('addresstext',{static:false}) addresstext: any;
  //
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];

  @Output() currentAddress: string = "";
  //Key : b30c1f
  ngOnInit() {
    this.bookingService.contactForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      phone: new FormControl(undefined, [Validators.required]),
      pin: new FormControl('', [])
    });
    this.bookingService.kioskKey = new FormGroup({
      kiosk_key: new FormControl('', [])
    });

    if (!localStorage.getItem('kiosk_key')) {
      this.bookingService.changeBookingStage(4);
      return;
    }
    this.loadKiosk(localStorage.getItem('kiosk_key'));
  }

  changePreferredCountries() {
		this.preferredCountries = [CountryISO.India, CountryISO.Canada];
	}

  loadKiosk(key: string) {
    this.bookingService.getKiosk(key).subscribe(res => {
      this.bookingService.changeBookingStage(0);
      localStorage.setItem('kiosk_key', key);
      this.coordinatesFrom.push(res['data']['lat']);
      this.coordinatesFrom.push(res['data']['lng']);
      console.log(this.coordinatesFrom);
    }, err => {
      alert("Incorrect Kiosk Key.");
    });
  }

  return(openKeyboard?) {
    this.bookingService.contactForm.reset();
    this.transportName = null;
    this.transportType = null;
    this.bookingService.value = "";
    this.bookingService.keyboard.setInput("");
    this.bookingService.keyboardNumeric.setInput("");
    this.bookingService.changeBookingStage(0);
    if (openKeyboard) {
      this.toggleKeyboard(true);
    }
  }

  sendBooking() {
    if (this.bookingService.contactForm.valid) {
      this.bookingService.setForm(this.bookingService.contactForm);
      this.bookingService.setCoordinatesFrom(this.coordinatesFrom);
      this.bookingService.setCoordinatesTo(this.coordinatesTo);

      this.bookingService.postTripDetails().subscribe((msg) => {
        console.log(msg);
      },
        err => {
          console.log(err);
        });

      this.bookingService.changeBookingStage(3);
        var _this = this;
      setTimeout(function() {
        _this.return();
      }, 30000)

    }
    else {
      alert("Example booking failed!");
    }
  }

  selectTransport(type: number) {
    switch (type) {
      case 1:
        if (!this.carsDisabled) {
          this.transportName = "Comfort";
          this.bookingService.changeBookingStage(this.bookingService.bookingStage + 1);
          this.transportType = type;
          this.bookingService.transportType = type;
        }
        break;
      case 5:
        if (!this.vansDisabled) {
          this.transportName = "Van";
          this.bookingService.changeBookingStage(this.bookingService.bookingStage + 1);
          this.transportType = type;
          this.bookingService.transportType = type;
        }
        break;
    }
  }

  stepBack() {
    this.bookingService.changeBookingStage(this.bookingService.bookingStage - 1);
    if (this.bookingService.bookingStage == 0) {
      this.bookingService.contactForm.reset();
      this.transportName = null;
      this.transportType = null;
      this.bookingService.value = "";
      this.bookingService.keyboard.setInput("");
      this.bookingService.keyboardNumeric.setInput("");
    }
  }

  activeElement : number;

  setActive(choice: number) {
    this.activeElement = choice;
    switch (choice) {
      case 1:
        document.getElementById('inp-holder-phone-2').hidden = true;
        document.getElementById('inp-holder-pin').hidden = true;
        break;
      case 2:
        document.getElementById('inp-holder-name').hidden = true;
        document.getElementById('inp-holder-pin').hidden = true;
        break;
      case 3:
        document.getElementById('inp-holder-phone-2').hidden = true;
        document.getElementById('inp-holder-name').hidden = true;
        break;
    }
    if(this.activeElement == 2){
      this.bookingService.keyboardDisplayNumeric = true;
      this.handleShift();
      this.handleShift();
    }
    else{
      this.bookingService.keyboardDisplayNumeric = false;
      this.handleShift();
      this.handleShift();
    }
  }

  closeKeyboard() {
    if (this.bookingService.bookingStage == 0) {
      this.bookingService.contactForm.reset();
      this.transportName = null;
      this.transportType = null;
      this.bookingService.value = "";
      this.bookingService.keyboard.setInput("");
      this.bookingService.keyboardNumeric.setInput("");
    }
    this.bookingService.keyboardDisplay = false;

    if (document.getElementById('inp-holder-phone-2'))
    document.getElementById('inp-holder-phone-2').hidden = false;
    if (document.getElementById('inp-holder-pin'))
    document.getElementById('inp-holder-pin').hidden = false;
    if (document.getElementById('inp-holder-name'))
      document.getElementById('inp-holder-name').hidden = false;
  
  }
  

  handleShift = () => {
    if(this.bookingService.keyboardDisplayNumeric){
      let currentLayout = this.bookingService.keyboardNumeric.options.layoutName;
      let shiftToggle = currentLayout === "default" ? "shift" : "default";
  
      this.bookingService.keyboardNumeric.setOptions({
        layoutName: shiftToggle
      });
    }
    else{
      let currentLayout = this.bookingService.keyboard.options.layoutName;
    let shiftToggle = currentLayout === "default" ? "shift" : "default";

    this.bookingService.keyboard.setOptions({
      layoutName: shiftToggle
    });
    }
  };

  toggleKeyboard(turnOn?, defaultValue?) {
    this.bookingService.keyboardDisplayNumeric = false;
    this.handleShift();
    this.handleShift();
    this.bookingService.keyboardDisplay = turnOn ? turnOn : !this.bookingService.keyboardDisplay;
    this.bookingService.keyboard.setInput(defaultValue ? defaultValue : "");
    this.bookingService.keyboardNumeric.setInput(defaultValue ? defaultValue : "");
  }

  getEstablishmentAddress(event) {
    let _this = this;
    this.vansDisabled = true;
    this.carsDisabled = true;
    this.prices = [];
    this.waitingTimes = [];
    this.waitingTimeLoaded = false;
    this.currentAddress = event['name'];
    let coords = JSON.parse(JSON.stringify(event['geometry']['location']));
    this.coordinatesTo = [+coords['lat'], +coords['lng']];
    this.bookingService.getTripDetails(this.coordinatesFrom[0], this.coordinatesFrom[1], +coords['lat'], +coords['lng']).subscribe((value) => {
      this.durations.push(value['data']['prices'][_this.vehicleTypes.COMFORT]['waiting_time']['text']);
      this.durations.push(value['data']['prices'][_this.vehicleTypes.VAN]['waiting_time']['text']);
      this.bookingService.lastPrice = value['data'];
      if (+value['data']['prices'][_this.vehicleTypes.VAN]['waiting_time']['value'] > -1) {
        this.vansDisabled = false;
      }

      if (+value['data']['prices'][_this.vehicleTypes.COMFORT]['waiting_time']['value'] > -1) {
        this.carsDisabled = false;
      }

      this.prices.push(+value['data']['prices'][_this.vehicleTypes.COMFORT]['from']);
      this.prices.push(+value['data']['prices'][_this.vehicleTypes.COMFORT]['to']);
      this.prices.push(+value['data']['prices'][_this.vehicleTypes.VAN]['from']);
      this.prices.push(+value['data']['prices'][_this.vehicleTypes.VAN]['to']);
      this.waitingTimes.push(value['data']['prices'][_this.vehicleTypes.COMFORT]['waiting_time']['text'].split(" ")[0]);
      this.waitingTimes.push(value['data']['prices'][_this.vehicleTypes.VAN]['waiting_time']['text'].split(" ")[0]);
      this.waitingTimeLoaded = true;
      this.zone.run(() => {
      });
    });
    this.bookingService.changeBookingStage(1);
    this.zone.run(() => {
    });
  }
}