import { Component, ViewChild, EventEmitter, Output, OnInit, AfterViewInit, Input } from '@angular/core';
import { BookingService } from './booking.service';

declare var google;
@Component({
    selector: 'AutocompleteComponent',
    template: `
      <input class="input form-control styledInput"
        type="text" id="google-input" (input)="onInputChange($event)" value={{bookingService.value}}
        [(ngModel)]="autocompleteInput" placeholder="Where to?"
        #addresstext style="padding: 12px 20px; border: 1px solid #ccc; background-color: gray; color: white; border-radius: 15px; font-size: 30px;"
        >
    `,
})
export class AutocompleteComponent implements OnInit, AfterViewInit {
    @Input() adressType: string;
    @Output() setAddress: EventEmitter<any> = new EventEmitter();
    @ViewChild('addresstext',{static:false}) addresstext: any;

    autocompleteInput: string;
    queryWait: boolean;

    value = "";

    constructor(private bookingService : BookingService) {
    }

    ngOnInit() {
        this.setAddress.subscribe((data) => {
            console.log(data);
        });
        this.value = this.bookingService.getValue();
    }

    return() {
        if(this.bookingService.bookingStage > 0)
            this.bookingService.changeBookingStage(0);
    }

    ngAfterViewInit() {
        this.getPlaceAutocomplete();
    }
    
    private getPlaceAutocomplete() {
        if(google){
            const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement,
                {
                    componentRestrictions: { country: 'MT' },
                    types: [this.adressType]  // 'establishment' / 'address' / 'geocode'
                });
            google.maps.event.addListener(autocomplete, 'place_changed', () => {
                const place = autocomplete.getPlace();
                this.invokeEvent(place);
            });
        }
    }

    invokeEvent(place: Object) {
        this.setAddress.emit(place);
    }

}