import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BookingService } from '../booking.service';
import Keyboard from 'simple-keyboard';
import { resetFakeAsyncZone } from '@angular/core/testing';

@Component({
  selector: 'app-keyboard',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './keyboard.component.html',
  styleUrls: [
    '../../../node_modules/simple-keyboard/build/css/index.css',
    './keyboard.component.css']
})
export class KeyboardComponent implements OnInit {

  constructor(private bookingService: BookingService) { }

  ngOnInit() {
  }

  ngAfterViewInit() { 
    this.bookingService.keyboardNumeric = new Keyboard({
      onChange: input => this.onChange(input),
      onKeyPress: button => this.onKeyPress(button),
      display: {
        '{enter}': "DONE",
        '{bksp}' : "Backspace",
        '{shift}' : "Shift",
        '{tab}' : "Tab",
        '{lock}' : "Caps Lock"
      },
      layout: {
        default: ["1 2 3", "4 5 6", "7 8 9", "{bksp} 0 {enter}"],
        shift: ["! / #", "$ % ^", "& * (", ") _", "{bksp} {enter}"]
      },
      theme: "hg-theme-default hg-layout-numeric numeric-theme myTheme1"
    });
    
    this.bookingService.keyboard = new Keyboard({
      onChange: input => this.onChange(input),
      onKeyPress: button => this.onKeyPress(button),
      theme: "hg-theme-default myTheme1",
      display: {
        '{enter}': "DONE",
        '{bksp}' : "Backspace",
        '{shift}' : "Shift",
        '{tab}' : "Tab",
        '{lock}' : "Caps Lock",
        "{space}" : "Space"
      },
      layout: {
        default: [
          "` 1 2 3 4 5 6 7 8 9 0 {bksp}",
          "{tab} q w e r t y u i o p \\",
          "{lock} a s d f g h j k l ; ' {enter}",
          "{shift} z x c v b n m , . / {shift}",
          "@ {space}"
        ],
        shift: [
          "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
          "{tab} Q W E R T Y U I O P { } |",
          '{lock} A S D F G H J K L : " {enter}',
          "{shift} Z X C V B N M < > ? {shift}",
          ".com @ {space}"
        ]
      }
    });
  }

  onChange = (input: string) => {
    // if(document.getElementById('google-input')){
    //   document.getElementById('google-input').focus();
    //   document.getElementById('google-input').blur();
    //   this.bookingService.value = input;
    // }
    if(input === 'RESET')
    {
      localStorage.removeItem('kiosk_key');
      console.log('reseted kiosk');
      window.location.reload();
    }
    setTimeout(() => {
      console.log(input);
      if(document.getElementById('google-input')){
        this.bookingService.value = input;
        document.getElementById('google-input').focus();
      }
      if(document.getElementById('kiosk_key')){
        this.bookingService.kioskKey.get('kiosk_key').setValue(input);
      }
      if(document.getElementById('inp-holder-name')){
        if(!document.getElementById('inp-holder-name').hidden){
          this.bookingService.contactForm.get('name').setValue(input);
        }
      }
      if(document.getElementById('inp-holder-phone-2')){
        if(!document.getElementById('inp-holder-phone-2').hidden){
          this.bookingService.contactForm.get('phone').setValue(input);
        }
      }
      if(document.getElementById('inp-holder-pin')){
        if(!document.getElementById('inp-holder-pin').hidden){
          this.bookingService.contactForm.get('pin').setValue(input);
        }
      }
    }, 100);
  };

  onKeyPress = (button: string) => {
    if (button === "{shift}" || button === "{lock}") this.handleShift();
    if (button === "{enter}") {
      this.removeKeyboard();
      return '';
    }
  };

  removeKeyboard() {
    this.bookingService.keyboardDisplay = false;
    if (document.getElementById('phone-input'))
    document.getElementById('phone-input').hidden = false;
    if (document.getElementById('pin-input'))
    document.getElementById('pin-input').hidden = false;
    if (document.getElementById('name-input'))
    document.getElementById('name-input').hidden = false;
    
    if (document.getElementById('inp-holder-phone-2'))
    document.getElementById('inp-holder-phone-2').hidden = false;
    if (document.getElementById('inp-holder-pin'))
    document.getElementById('inp-holder-pin').hidden = false;
    if (document.getElementById('inp-holder-name'))
      document.getElementById('inp-holder-name').hidden = false;
  }

  onInputChange = (event: any) => {
    if(this.bookingService.keyboardDisplayNumeric){
      this.bookingService.keyboardNumeric.setInput(event.target.value);
    }
    else{
      this.bookingService.keyboard.setInput(event.target.value);
      console.log("This: ",event.target.value);
    }
  };

  handleShift = () => {
    if(this.bookingService.keyboardDisplayNumeric){
      let currentLayout = this.bookingService.keyboardNumeric.options.layoutName;
      let shiftToggle = currentLayout === "default" ? "shift" : "default";
  
      this.bookingService.keyboardNumeric.setOptions({
        layoutName: shiftToggle
      });
    }
    else{
      let currentLayout = this.bookingService.keyboard.options.layoutName;
    let shiftToggle = currentLayout === "default" ? "shift" : "default";

    this.bookingService.keyboard.setOptions({
      layoutName: shiftToggle
    });
    }
  };

}
