import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../environments/environment';
import Keyboard from "simple-keyboard";
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})

export class BookingService {


  contactForm: FormGroup;
  kioskKey: FormGroup;
  coordinatesFrom: number[] = [];
  coordinatesTo: number[] = [];
  transportType : number;
  bookingStage: number = 0;
  lastPrice = {
  }
  value = "";
  keyboardDisplay = false;
  keyboardDisplayNumeric = false;
  keyboard: Keyboard;
  keyboardNumeric: Keyboard;

  getKeyboard() : Keyboard{
    return this.keyboard;
  }

  setForm(updatedForm : FormGroup){
    this.contactForm = updatedForm;
  }

  setCoordinatesTo(coordinatesTo : number[]){
    this.coordinatesTo = coordinatesTo;
  }

  setCoordinatesFrom(coordinatesFrom : number[]){
    this.coordinatesFrom = coordinatesFrom;
  }

  getValue() : string{
    return this.value;
  }

  changeBookingStage(stage : number){
    this.bookingStage = stage;
    if(this.bookingStage > 0){
      if(this.keyboardDisplay){
        this.keyboardDisplay = false;
      }
    }
  }

  postTripDetails(){
    //"/ytaxi/job"
    //this.durations.push(value['data']['prices']
    var jobDetail = {
        customer: {
            first_name: this.contactForm.get('name').value,
            phone: this.contactForm.get('phone').value.number.toString().trim(),
            country_code: this.contactForm.get('phone').value.dialCode.toString().trim()
        },
        pickup: {
            lat: this.coordinatesFrom[0],
            lng: this.coordinatesFrom[1],
            address: this.lastPrice['pickup']['address'] == null ? '' : this.lastPrice['pickup']['address'] ,
        },
        dropoff: {
          lat: this.coordinatesTo[0],
          lng: this.coordinatesTo[1],
          address: this.lastPrice['dropoff']['address'] == null ? '' : this.lastPrice['dropoff']['address'],
      },
        vehicle_type: this.transportType,
        payment_method: 3, // Forced to cash
        type: "taxi",
        //coupon: null,
        source: 'kiosk',
        kiosk_id: localStorage.getItem('kiosk_key'),
        kiosk_pin:  '',
        //kiosk_pin:  this.contactForm.get('pin') ? this.contactForm.get('pin').value.toString().trim() : '',
    }
    return this.http.post<any>(`${environment.baseUrl}ytaxi/job`, jobDetail)
    .pipe(map(res => {
      return res;
    }));
  }

  constructor(private http : HttpClient) { }

  getKiosk(kioskKey : string){
    return this.http.get<any>(`${environment.baseUrl}kiosk/location?key=`+kioskKey)
    .pipe(map(res => {
      return res;
    }));
  }

  getTripDetails(from_lat,from_lng,to_lat,to_lng){
    const  params = new  HttpParams().set('pickup_lat', from_lat).set('pickup_lng', from_lng).set('dropoff_lat', to_lat).set('dropoff_lng', to_lng).set('type', "taxi").set('kiosk', localStorage.getItem('kiosk_key'));
    return this.http.get<any>(`${environment.baseUrl}ytaxi/job/price/estimate`, {params})
    .pipe(map(res => {
      return res;
    }));
  }



}
